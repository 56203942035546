<template>
  <div class="animated fadeIn">
    <div class="table-container">
      <div class="list-wrapper">
        <div>
          <div class="j_datagrid__header type01">
            <div class="header__title">
              <h5>
                {{ $store.state.sysenv.codePropagated.name }}
                <small>{{$store.state.sysenv.codePropagated.parentName}}</small>
              </h5>
            </div>

            <div class="header__right">
              <v-flex>
                <button class="jcon_add" @click="setAdd()"></button>
              </v-flex>
            </div>
          </div>

          <j-data-grid
            v-model="selected"
            item-key="virtualSn"
            hide-actions
            draggable
            :headers="headers"
            :items="navSubItem"
            @row-move-to="onRowMoved"
            @sorted="onSorted"
          >
            <template v-slot:items="props">
              <tr :active="props.selected" @click="propStatus($event,props)">
                <td style="text-align: center;" v-if="codePropagated.code !== 'ALL'" class="handle">
                  <button class="jcon_order"></button>
                </td>
                <td v-if="codePropagated.code === 'ALL'">{{ props.item.itemName }}</td>
                <td style="text-align: center;">{{ props.item.no }}</td>
                <td>
                  <div style="display:flex;">
                    {{ props.item.subItemName }}
                    <img
                      v-if="!!props.item.subItemIcon"
                      style="margin-left: 3px;"
                      height="14px"
                      :src="props.item.subIconPath"
                    />
                  </div>
                </td>
                <td>{{ props.item.subStatusName }}</td>
                <td style="text-align: center;">{{ props.item.toolBar }}</td>
                <td style="text-align: center;">{{ props.item.nonFilter }}</td>
                <td style="text-align: center;">{{ props.item.exportPdf }}</td>
                <td>{{ props.item.subPermitName }}</td>
                <!-- <td v-html="formatWButton(props.item.reportName)"></td> -->
                <td v-html="formatWButton(props.item.reportName)"></td>
                <!-- <td>{{ props.item.url }}</td> -->
                <td>{{ props.item.cnName }}</td>
                <td>{{ props.item.pdfTime }}</td>
                <td>{{ props.item.customCompoment }}</td>                
                <td>{{ props.item.direction }}</td>
                <td v-if="UpdateVisible" @click="dbTranster(props)">Update</td>
              </tr>
            </template>
          </j-data-grid>
        </div>
      </div>

      <j-form-modal
        title="Sub-Item"
        ref="subItemFormHandler"
        @create="add()"
        @edit="edit()"
        @delete="del()"
        @cancel="cancel()"
        :formMode="formode"
        :resetable="resetable()"
        :opened.sync="modOpen"
      >
        <v-container>
          <v-layout wrap>
            <v-flex
              v-if="codePropagated.code == 'ALL' && formode == 'NEW'"
              xs12
              sm6
              class="wrap__select"
            >
              <v-select
                v-model="selectedItem.groupCode"
                item-text="name"
                item-value="code"
                label="Group"
                placeholder="Select a Group"
                class="field"
                dense
                required
                :menu-props="{contentClass:'single_select'}"
                :items="groupOptions"
                :rules="codeRules"
                @input="groupChanged"
              ></v-select>
            </v-flex>

            <v-flex
              v-if="codePropagated.code == 'ALL' && formode == 'NEW'"
              xs12
              sm6
              class="wrap__select"
            >
              <v-select
                v-model="selectedItem.itemCode"
                item-text="name"
                item-value="code"
                label="Item"
                placeholder="Select a Item Title"
                class="field"
                dense
                required
                :menu-props="{contentClass:'single_select'}"
                :items="itemOptions"
                :rules="codeRules"
              ></v-select>
            </v-flex>

            <v-flex xs12 class="wrap__picker">
              <v-text-field
                v-model="selectedItem.subItemName"
                label="Sub-Item Title"
                placeholder="Input/Edit Sub-Item Title"
                required
                :rules="nameRules"
              ></v-text-field>
              <v-menu
                v-model="iconPickerOpen"
                content-class="j_picker icon_picker"
                offset-y
                :close-on-content-click="false"
                :min-width="300"
                :nudge-left="18"
              >
                <template v-slot:activator="{ on }">
                  <div class="picker">
                    <span class="label">icon</span>
                    <v-btn
                      v-on="on"
                      @click="popIconPicker(!iconPickerOpen)"
                      :style="'background-image: url(' + icon.filePath + '); background-size: 100%;'"
                    ></v-btn>
                  </div>
                </template>
                <v-card>
                  <div class="j_picker__header">
                    <span>
                      Font Icon Picker
                      <small>FONT</small>
                    </span>
                  </div>
                  <div class="j_picker__contents">
                    <div class="current_icon">
                      <div>
                        <button @click="removeIcon">
                          <v-icon>mdi-close</v-icon>
                        </button>
                        <img v-show="!!icon.filePath&& icon.filePath != ''" :src="icon.filePath" />
                      </div>
                      <span>{{icon.fileName}}</span>
                    </div>
                    <div class="select_icon">
                      <button
                        v-for="icon in iconList"
                        :value="icon.name"
                        :key="icon.iconCode"
                        @click="iconSelect(icon)"
                      >
                        <img :src="icon.filePath" />
                      </button>
                    </div>
                  </div>
                </v-card>
              </v-menu>
            </v-flex>

            <v-flex xs12 sm6>
              <v-flex xs12 class="wrap__option_group">
                <span class="label">Sub-Item Status</span>
                <div class="option_group">
                  <label :for="option.text" v-for="option in statusOptions" :key="option.index">
                    <input
                      v-model="selectedItem.subItemStatus"
                      type="radio"
                      name="subItemStatus"
                      :id="option.text"
                      :value="option.value"
                      :checked="selectedItem.subItemStatus == option.value"
                    />
                    {{ option.text }}
                  </label>
                </div>
              </v-flex>

            <v-flex xs12>
              <v-text-field
                v-model="selectedItem.cnName"
                required
                label="CN Name"
                placeholder="Input/Edit Cn Name"
              ></v-text-field>
            </v-flex> 
            
            <v-flex xs12>
              <v-text-field
                v-model="selectedItem.pdfTime"
                required
                label="Pdf Export Time"
                placeholder="Input/Edit Pdf Export Time"
              ></v-text-field>
            </v-flex> 
                          
              <v-flex xs12>
                <j-radio-group
                  v-model="selectedItem.toolBar"
                  label="Tool Bar"
                  name="toolBar"
                  :checked="'N'"
                  :options="[
                    { text: 'Yes', value: 'Y' },
                    { text: 'No', value: 'N' },
                  ]"
                />
              </v-flex>

              <v-flex xs12>
                  <j-radio-group
                    v-model="selectedItem.nonFilter"
                    label="Non-Historical-Filter"
                    name="nonFilter"
                    :checked="'N'"
                    :options="[
                      { text: 'Yes', value: 'Y'},
                      { text: 'No', value: 'N'},
                    ]"
                  />
                </v-flex>

              <v-flex xs12>
                  <j-radio-group
                    v-model="selectedItem.exportPdf"
                    label="Export-Pdf"
                    name="exportPdf"
                    :checked="'Y'"
                    :options="[
                      { text: 'Yes', value: 'Y'},
                      { text: 'No', value: 'N'},
                    ]"
                  />
                </v-flex>
            </v-flex>

            <v-flex xs12 sm6 class="wrap__multiselect">
              <v-select
                v-model="subItemPermitSelected"
                multiple
                return-object
                label="Sub-Item Permission"
                item-text="name"
                placeholder="Select permissions to apply"
                :menu-props="{ maxHeight: '400', contentClass:'multi_select' }"
                :items="subItemPermitOptions"
              ></v-select>
            </v-flex>

            <v-flex xs12 class="wrap__button_group" v-show="formode == 'MOD' && wButton.length > 0">
              <span>Writable Button</span>
              <div class="button_group">
                <v-btn
                  class="writable j_button"
                  v-for="item in wButton"
                  :key="item.index"
                  @click="openWButton(item)"
                >{{item.reportName}}</v-btn>
              </div>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="selectedItem.customCompoment"
                required
                label="Custom Compoment"
                placeholder="Input/Edit Compoment Name"
              ></v-text-field>
            </v-flex>  

            <v-flex xs12 sm6 class="wrap__select">
              <v-select
                v-model="selectedItem.direction"
                dense
                required
                label="Direction"
                class="field"
                item-text="text"
                item-value="value"
                placeholder="Select a Target"
                :menu-props="{contentClass:'single_select'}"
                :rules="codeRules"
                :items="componentDirectionOptions"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-container>
      </j-form-modal>
    </div>

    <j-alert
      v-model="msgOpen"
      @yes="yes()"
      @cancel="msgOpen = false"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
    ></j-alert>

    <j-writable-box
      formode="MOD"
      :open.sync="writableBoxOpen"
      :receivedItem="writableBoxReqItem"
      :comparedItem="writableBoxComparedItem"
      @changed="writableBoxChanged"
      @yes="yes()"
    ></j-writable-box>

    <j-snackbar
      :snackbar="snackbar"
      :snackbarColor="'success'"
      :closeBtnColor="'buttonColor'"
      :positionX="'top'"
      :positionY="'right'"
      :top="'115px'"
      :timeout="2000"
      :mode="'mode'"
      :text="'Priority changed'"
    ></j-snackbar>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { SystemService, SystemCodeService } from "@/services"
import JFormModal from "@/components/floating/JFormModal"
import JWritableBox from "@/components/popup/JWritableBox"
import JSnackbar from "@/components/JSnackbar"
import StaticOptions from '@/mixins/staticOptions'
import Loading from '@/mixins/loading.mixin'

export default {
  name: "sysenv_menu_subitem",
  mixins: [
    Loading,
    StaticOptions
  ],
  components: {
    JFormModal,
    JSnackbar,
    JWritableBox
  },
  data: () => ({
    UpdateVisible: true,
    systemService: null,
    systemCodeService: null,
    snackbar: false,
    modOpen: false,
    groupOptions: [],
    itemOptions: [],
    subItemPermitOptions: [],
    subItemPermitSelected: [],
    statusOptions: [],
    iconPickerOpen: false,
    iconList: [],
    icon: {},
    selected: [],
    selectedIndex: -3,
    selectedItem: {},
    selectedPrevItem: [],
    headers: [
      { type: "drag", text: "", align: "right", value: "", width: 30 },
      {
        type: "text",
        text: "Item Title",
        align: "left",
        value: "itemName",
        width: 170
      },
      { type: "conf", text: "No", align: "center", value: "no", width: 50 },
      {
        type: "text",
        text: "Title",
        align: "left",
        value: "subItemName",
        width: 200
      },
      {
        type: "text",
        text: "Status",
        align: "left",
        value: "subStatusName",
        width: 200
      },
      {
        type: "text",
        text: "Tool",
        align: "center",
        value: "toolBar",
        width: 40
      },
      {
        type: "text",
        text: "Non-Historical-Filter",
        align: "center",
        value: "nonFilter",
        width: 40
      },  
      {
        type: "text",
        text: "Export-Pdf",
        align: "center",
        value: "exportPdf",
        width: 40
      },      
      {
        type: "text",
        text: "Permission",
        align: "left",
        value: "subPermitName"
      },
      {
        type: "text",
        text: "Writable Button",
        align: "left",
        value: "",
        width: 200
      },
      {
        type: "text",
        text: "CnName",
        align: "left",
        value: "",
        width: 50
      },
      {
        type: "text",
        text: "PdfTime",
        align: "left",
        value: "",
        width: 50
      },
      { type: "text", text: "Custom Compoment", align: "left", value: "customCompoment", width: 150 },             
      { type: "text", text: "Direction", align: "left", value: "direction", width: 150 },
      { type: "text", text: "update", align: "left", value: "update",  }
    ],
    navSubItem: [],
    // loading: false,
    select: null,
    formode: __C.FORM.EDIT_MODE_NEW,
    valid: false,
    defaultRules: [v => !!v || "Required"],
    codeRules: [v => (!!v && v != "ALL" && v.length > 0) || "Required"],
    nameRules: [
      v => !!v || "Required",
      v => (!!v && v.length <= 80) || "Less then 80 characters"
    ],
    pathRules: [
      v =>
        /^[a-zA-Z0-9@/]*$/.test(v) ||
        "Not allowed space and special letters except @ /"
    ],
    msgOpen: false,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    yes: () => { },
    wButton: [],
    writableBoxOpen: false,
    writableBoxReqItem: {},
    writableBoxComparedItem: {},
    writableStatus: ""
  }),
  watch: {
    // this.systemService.getNavMenuForSubItem
    modOpen(val) {
      if (
        !val &&
        (this.writableStatus == "UPDATE" || this.writableStatus == "DELETE")
      ) {
        this.systemService.getNavSubItem(
          this.setNavSubItem,
          this.codePropagated.parentCode,
          this.codePropagated.code
        );
      }
    },
    subItemPermitSelected(val) {
      let menuPermits = [];
      val.forEach(m => {
        menuPermits.push(m.code);
      });
      this.selectedItem.subItemPermit = menuPermits.join(",");
    },
    codePropagated: {
      handler(val) {
        this.headersChange(val); // headers 변경
        // to retain or set page's default status in 'Add'
        this.selectedIndex = -3;
        // sortable 작업 후 하위 메뉴 이동시 table 강제로 리로드
        this.navSubItem = [{ dummy: "dummy" }];

        this.systemCodeService.subItemGroup(val.parentCode, res => {
          if (!res) this.groupOptions = [];
          else this.groupOptions = res;

          // retrieve the list by new condition
          this.systemService.getNavSubItem(
            this.setNavSubItem,
            val.parentCode,
            val.code
          );
        });
      },
      deep: true
    }
  },
  created() {
    this.systemService = new SystemService();
    this.systemCodeService = new SystemCodeService();
  },
  mounted() {
    this.systemCodeService.subItemGroup(
      this.codePropagated.parentCode,
      res => {
        if (!res) this.groupOptions = [];
        else this.groupOptions = res;

        this.systemService.getCodeIndex("PERMISSION", res => {
          if (!res) this.subItemPermitOptions = [];
          else this.subItemPermitOptions = res;

          this.systemService.getCodeIndex("SVC_STATUS", res => {
            if (!res) this.statusOptions = [];
            else
              res.forEach(item => {
                this.statusOptions.push({
                  text: item.name,
                  value: item.code
                });
              });

            this.systemService.getNavSubItem(
              this.setNavSubItem,
              this.codePropagated.parentCode,
              this.codePropagated.code
            );
          });
        });
      }
    );
    this.headersChange(this.codePropagated); // headers 변경
  },
  computed: {
    codePropagated: {
      get() {
        return this.$store.state.sysenv.codePropagated;
      },
      set(value) {
        this.$store.commit("sysenv/codePropagate", value);
      }
    },
    menuUpdated: {
      get() {
        return this.$store.state.sysenv.menuUpdated;
      },
      set(value) {
        this.$store.commit("sysenv/setMenuStatusUpdated", value);
      }
    }
  },
  methods: {
    setWButton(res) {
      this.wButton = []
      if (!res) res = [];
      this.wButton = res;
    },
    openWButton(item) {
      this.writableBoxReqItem = Object.assign({}, item);
      this.writableBoxComparedItem = Object.assign({}, item);
      this.writableBoxOpen = true;
    },
    writableBoxChanged(status) {
      // Menu Item 등록/수정화면에서 나갔을 때 Data Table을 re-render
      // UPDATE, DELETE 값의 경우만 re-render, CANCLE 에서는 X
      this.writableStatus = status;

      // if ((status == "UPDATE" || status == "DELETE")) {
      //   this.systemService.getNavSubItem(
      //     this.setNavSubItem,
      //     this.codePropagated.parentCode,
      //     this.codePropagated.code
      //   )
      // }

      this.writableBoxOpen = false
      if (status !== void 0) {
        setTimeout(() => {
          // Menu Item 등록/수정화면의 Writable Data re-render
          this.systemService.getWButton(this.setWButton, this.selectedItem.phaseCode, this.selectedItem.groupCode, this.selectedItem.itemCode, this.selectedItem.idx)
        }, 200);
      }
    },
    iconSelect(icon) {
      this.icon = icon;
      this.selectedItem.subItemIcon = icon.iconCode;
      this.selectedItem.subIconName = icon.fileName;
      this.selectedItem.subIconPath = icon.filePath;
    },
    removeIcon(e) {
      this.icon = {};
      this.selectedItem.subItemIcon = "";
      this.selectedItem.subIconPath = "";
      this.selectedItem.subIconName = "";
    },
    popIconPicker(opened) {
      if (!opened) return;

      this.systemService.getIcons(__C.IMAGE.ICON_TYPE_FONT, res => {
        if (!res) res = [];
        this.iconList = res;
      });
    },
    groupChanged(groupCode) {
      if (!groupCode) return;

      this.systemCodeService.subItemItem(
        this.codePropagated.parentCode,
        groupCode,
        res => {
          if (!res) this.itemOptions = [];
          else this.itemOptions = res;
        }
      );
    },
    setNavSubItem(res) {
      this.modOpen = false;
      if (!res) res = [];
      // console.log(`res: %s`, JSON.stringify(res))

      // 'no' field is generated by v-data-table's items="props" action
      // because of that props.item.no is defined to the data-date' item template
      // it doesn't need to add manually...
      // res.forEach(item => { item['no'] = 0 })
      this.navSubItem = res;

      // at the very first time access or page reloading,
      // it comes twise from 'watcher' and 'mounted' event
      if (this.selectedIndex < -1) {
        ++this.selectedIndex;

        // if this action comes from that add new menu item,
        // right added item should be selected manually.
      } else if (this.selectedIndex == -1) {
        // Attention, this is itemName, not the itemCode...
        this.setEdit(
          this.navSubItem.findIndex(x => x.idx === this.selectedItem.idx)
        );
      } else {
        // if this action comes from that delete existing menu item,
        // select a forward or backward item.
        if (
          this.navSubItem.findIndex(x => x.idx === this.selectedItem.idx) < 0
        ) {
          if (this.navSubItem.length > 0) {
            if (this.navSubItem.length >= this.selectedIndex + 1) {
              // Nothing to do...
            } else {
              this.selectedIndex = this.navSubItem.length - 1;
            }
            this.setEdit(this.selectedIndex);
          } else {
            // 삭제시 등록화면 호출 부분 제거
            // this.setAdd()
          }
        } else {
          this.setEdit(this.selectedIndex);
        }
      }
    },
    setUrl() {
      let group = this.groupOptions.find(g => g.code == this.selectedItem.groupCode)
      let item = this.itemOptions.find(t => t.code == this.selectedItem.itemCode)

      this.selectedItem.urlSelf = `/${this.selectedItem.subItemName.toLowerCase().replace(/[\W]+/g, '_')}`
      this.selectedItem.url = (
        '/service' +
        `/${this.codePropagated.parentName.toLowerCase().replace(/[\W]+/g, '_')}` +
        `/${group.name.toLowerCase().replace(/[\W]+/g, '_')}` +
        `/${item.name.toLowerCase().replace(/[\W]+/g, '_')}` +
        this.selectedItem.urlSelf
      )
      this.selectedItem.type = 'Sub-Item'
    },
    onRowMoved(val) {
      console.log(val)
      const rowSelected = this.navSubItem.splice(val.oldIndex, 1)[0]; // Get the selected row and remove it
      this.navSubItem.splice(val.newIndex, 0, rowSelected); // Move it to the new index
      this.snackbar = false;
      this.systemService.setPriority(
        {
          newIdex: val.newIndex,
          oldIndex: val.oldIndex,
          targetName: "NAV_SUB_ITEM",
          conditionName1: this.codePropagated.parentCode,
          conditionName2: this.codePropagated.groupCode,
          conditionName3: this.codePropagated.code
        },
        () => {
          this.snackbar = true;
          this.systemService.getNavSubItem(
            (res) => { 
              this.navSubItem = []
              // Need some time-delay for draggable process
              setTimeout(() => { this.setNavSubItem(res) })
            }, 
            this.codePropagated.parentCode,
            this.codePropagated.code
          )
        }
      );
    },
    onSorted(items) {
      items.forEach((item, index) => {
        console.log(item.subItemName, item.no, index)
        item.no = index + 1;
      });
    },
    dbTranster(props) {
      const _self = this

      this.yes = () => {
        this.loading = true
        this.msgOpen = false
        this.systemService.transferSubItem(props.item, res => {
          this.loading = false
          _self.menuUpdated = true
        })
        this.yes = () => { }
      }
      this.msgInfo.type = "INFO"
      this.msgInfo.title = "Transfer Sub-Item."
      this.msgInfo.titleDescription = ""
      this.msgInfo.message = "Do you want to transfer Selected Menu Sub-Item?"
      this.msgInfo.button = [true, false, true]
      this.msgInfo.buttonText[0] = "Yes"
      this.msgOpen = true
    },    
    add() {
      const _self = this;
      this.yes = () => {
        this.msgOpen = false;
        this.setUrl()
        this.systemService.putNavSubItem(this.selectedItem, res => {
          this.systemService.getNavSubItem(
            this.setNavSubItem,
            this.codePropagated.parentCode,
            this.codePropagated.code
          );
          _self.menuUpdated = true;
        });
        this.yes = () => { };
      };
      this.msgInfo.type = "INFO";
      this.msgInfo.title = "Save Changes.";
      this.msgInfo.titleDescription = "";
      this.msgInfo.message = "Do you want to save new Menu Item?";
      this.msgInfo.buttonText[0] = "Save";
      this.msgOpen = true;
    },
    edit() {
      const _self = this;
      this.yes = () => {
        this.msgOpen = false;

        this.setUrl()
        this.systemService.updNavSubItem(this.selectedItem, res => {
          this.systemService.getNavSubItem(
            this.setNavSubItem,
            this.codePropagated.parentCode,
            this.codePropagated.code
          );
        });
        this.yes = () => { };
      };
      this.msgInfo.type = "INFO";
      this.msgInfo.title = "Save Changes.";
      this.msgInfo.titleDescription = "";
      this.msgInfo.message = "Do you want to save this changes?";
      this.msgInfo.buttonText[0] = "Save";
      this.msgOpen = true;
    },
    del() {
      const _self = this;

      this.yes = () => {
        this.msgOpen = false;
        this.systemService.delNavSubItem(this.selectedItem.idx, res => {
          this.systemService.getNavSubItem(
            this.setNavSubItem,
            this.codePropagated.parentCode,
            this.codePropagated.code
          );
          _self.menuUpdated = true;
        });
        this.yes = () => { };
      };
      this.msgInfo.type = "WARN";
      this.msgInfo.title = "Action Approval";
      this.msgInfo.titleDescription = "Important Notification";
      this.msgInfo.message = "Do you want to delete selected Menu Item?";
      this.msgInfo.buttonText[0] = "Delete";
      this.msgOpen = true;
    },
    querySelections(v) {
      this.loading = true;
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.navSubItem.filter(e => {
          return (
            (e.itemName || "").toLowerCase().indexOf((v || "").toLowerCase()) >
            -1
          );
        });
        this.loading = false;
      }, 500);
    },
    propStatus(e, props) {
      if (e.target.textContent == 'Update') this.modOpen = false
      else {
      // Writable 팝업 관련 : 수정 클릭시 Menu Item과 연결된 Wriatable Button 정보들을 가져온다.
      this.wButton = [];
      this.systemService.getWButton(
        this.setWButton,
        props.item.phaseCode,
        props.item.groupCode,
        props.item.itemCode,
        props.item.idx
      );

      this.selectedIndex = this.navSubItem.indexOf(props.item);
      this.selectedItem = Object.assign({}, props.item);

      this.icon = {};
      this.icon.iconCode = this.selectedItem.subItemIcon;
      this.icon.fileName = this.selectedItem.subIconName;
      this.icon.filePath = this.selectedItem.subIconPath;

      this.subItemPermitSelected = [];
      this.selectedItem.subItemPermit.split(",").forEach(code => {
        let group = this.subItemPermitOptions.find(o => o.code == code);
        if (!!group) this.subItemPermitSelected.push(group);
      });

      if (this.selectedPrevItem.length > 0) {
        if (this.selectedPrevItem[0].itemCode === props.item.itemCode) {
          this.modOpen = true;
          return;
        } else {
          let _selected = props.item;
          this.selected = [_selected];
        }
      } else {
        props.selected = !props.selected;
      }
      this.selectedPrevItem = [...this.selected];
      this.formode = __C.FORM.EDIT_MODE_MOD
      this.modOpen = true;

      this.groupChanged(this.selectedItem.groupCode);
      }
    },
    setAdd() {
      this.icon = {};
      // at the very first time access or page reloading,
      // it comes twise from 'watcher' and 'mounted' event.
      // so it should be retained to be increased by them until being -1.
      this.selectedIndex = this.selectedIndex < -1 ? this.selectedIndex : -1;
      this.formode = __C.FORM.EDIT_MODE_NEW
      this.modOpen = true;
      this.$refs.subItemFormHandler.formReset();

      setTimeout(() => {
        this.selectedItem = {
          phaseCode: this.codePropagated.parentCode,
          groupCode: this.codePropagated.groupCode,
          itemCode: this.codePropagated.code
        };
        this.selected = [];
        this.selectedPrevItem = [];
        this.subItemPermitSelected = [];
        if (this.codePropagated.code !== "ALL")
          this.groupChanged(this.selectedItem.groupCode);
      }, 100);
    },
    setEdit(selectedIndex) {
      // myArray.map(x => x.hello).indexOf('stevie')
      this.selectedIndex = selectedIndex;
      this.selectedItem = Object.assign(
        {},
        this.navSubItem[this.selectedIndex]
      );
      this.selected = [Object.assign({}, this.navSubItem[this.selectedIndex])];
      this.selectedPrevItem = [...this.selected];
      this.formode = __C.FORM.EDIT_MODE_MOD
    },
    resetable() {
      if (this.selectedIndex < 0) {
        if (
          (!!this.selectedItem.groupCode &&
            this.selectedItem.groupCode != "ALL") ||
          (!!this.selectedItem.itemCode &&
            this.selectedItem.itemCode != "ALL") ||
          !!this.selectedItem.subItemName ||
          !!this.selectedItem.subItemStatus ||
          !!this.selectedItem.subItemIcon ||
          !!this.selectedItem.subItem ||
          !!this.selectedItem.toolBar ||
          !!this.selectedItem.url ||
          !!this.selectedItem.direction ||
          !!this.selectedItem.cnName ||
          !!this.selectedItem.pdfTime ||
          !!this.selectedItem.nonFilter ||
          !!this.selectedItem.customCompoment ||
          !!this.selectedItem.exportPdf                    
        )
          return true;
        return false;
      } else {
        if (
          this.selectedPrevItem[0].subItemName !=
          this.selectedItem.subItemName ||
          this.selectedPrevItem[0].subItemPermit !=
          this.selectedItem.subItemPermit ||
          this.selectedPrevItem[0].subItemStatus !=
          this.selectedItem.subItemStatus ||
          this.selectedPrevItem[0].subItemIcon !=
          this.selectedItem.subItemIcon ||
          this.selectedPrevItem[0].subItem != this.selectedItem.subItem ||
          this.selectedPrevItem[0].toolBar != this.selectedItem.toolBar ||
          this.selectedPrevItem[0].url != this.selectedItem.url ||
          this.selectedPrevItem[0].direction != this.selectedItem.direction ||
          this.selectedPrevItem[0].cnName != this.selectedItem.cnName ||
          this.selectedPrevItem[0].pdfTime != this.selectedItem.pdfTime ||
          this.selectedPrevItem[0].nonFilter != this.selectedItem.nonFilter ||
          this.selectedPrevItem[0].customCompoment != this.selectedItem.customCompoment ||
          this.selectedPrevItem[0].exportPdf != this.selectedItem.exportPdf
        )
          return true;
        return false;
      }
    },
    reset() {
      if (this.selectedIndex < 0) {
        this.selectedItem = {};
        this.$refs.subItemFormHandler.formReset();

        setTimeout(() => {
          this.selectedItem =
            this.codePropagated.code !== "ALL"
              ? {
                itemCode: this.codePropagated.code,
                groupCode: this.codePropagated.groupCode,
                phaseCode: this.codePropagated.parentCode
              }
              : {};
          this.groupChanged(this.selectedItem.groupCode);
        });
      } else {
        this.selectedItem = Object.assign({}, this.selectedPrevItem[0]);
      }
    },
    cancel() {
      this.modOpen = false;
    },
    isCodeValid(itemCode) {
      return !this.navSubItem.find(item => item.itemCode == itemCode);
    },
    formatWButton(reportName) {
      var renderWB = "";
      if (reportName != "" && !!reportName) {
        var commaIndex = reportName.indexOf(",");
        if (commaIndex > -1) {
          reportName.split(",").forEach(val => {
            renderWB += `<v-btn class="writable j_button" >` + val + `</v-btn>`;
          });
        } else {
          renderWB +=
            `<v-btn class="writable j_button" >` + reportName + `</v-btn>`;
        }
      }
      return renderWB;
    },
    headersChange(val) {
      let groupIndex = this.headers.findIndex(h => h.value === "itemName");
      let target = `${__C.HOST_NAME_RESOURCE}`
      if (target == 'https://tortue.jingraphics.com/resources' || target == 'https://demo.jingraphics.com/resources') {
        let upIndex = this.headers.findIndex(h => h.text == 'update')
        if (upIndex > -1 ) this.headers.splice(upIndex, 1)
        this.UpdateVisible = false  
      }            
      if (val.code === "ALL") {
        // headers no 변경
        let noIndex = this.headers.findIndex(h => h.value === "no");
        if (noIndex > -1) this.headers[noIndex].value = "subItemSn";
        // headers Group 추가
        if (groupIndex < 0)
          this.headers.unshift({
            type: "text",
            text: "Item Title",
            align: "left",
            value: "itemName",
            width: 170
          });
        // headers dag 제거
        let dragIndex = this.headers.findIndex(h => h.type === "drag");
        if (dragIndex > -1) this.headers.splice(dragIndex, 1);
      } else {
        // headers no 변경
        let snIndex = this.headers.findIndex(h => h.value === "subItemSn");
        if (snIndex > -1) this.headers[snIndex].value = "no";
        // headers Group 제거
        if (groupIndex >= 0) this.headers.shift();
        // headers dag 추가
        let dragIndex = this.headers.findIndex(h => h.type === "drag");
        if (dragIndex == -1)
          this.headers.unshift({
            type: "drag",
            text: "",
            align: "right",
            value: "",
            width: 30
          });
      }
    }
  }
};
</script>
